<template>
    <div>
        <div class="my-flex-row">
            <div class="filter-botton" :class="{active: debugState == 'waiting_num'}" @click="debugState = 'waiting_num'">等待调试</div>
            <div class="filter-botton" :class="{active: debugState == 'debuging_num'}" @click="debugState = 'debuging_num'">正在调试</div>
            <div class="filter-botton" :class="{active: debugState == 'debuged_num'}" @click="debugState = 'debuged_num'">调试完成</div>
            <div class="right-item" @click="calendarOpt.showCalendar = true">
                <span class="right-item-text" style="">{{dateText}}</span>
                <img style="width: 14px;" src="../../assets/date.png" alt="">
            </div>
        </div>
        
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <device-info v-for="(item, i) in list" :item="item" :key="i" @click.native="toDeviceInfo(item)"></device-info>
        </van-list>
        
        <van-calendar v-model="calendarOpt.showCalendar" :max-date="calendarOpt.maxDate" :min-date="calendarOpt.minDate" @confirm="onCalendarConfirm" :lazy-render="false"></van-calendar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {formatDate} from '@/utils/common'
import deviceInfo from '@/components/DeviceInfo'
import {getEquipList} from '@/axios/device'
export default {
    components: {deviceInfo},
    computed: {
        ...mapGetters(['equipInfo']),
        dateText(){
            return formatDate(this.calendarOpt.date)
        }
    },
    data(){
        return {
            loading: false,
            finished: false,
            debugState: 'waiting_num',
            list: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            calendarOpt: {
                maxDate: new Date(),
                minDate: new Date((new Date().getFullYear() - 1) + '/01/01'),
                showCalendar: false,
                text: "选择时间",
                date: new Date()
            }
        }
    },
    watch: {
        debugState: function(_val){
            this.getEquipList(true)
        },
        "calendarOpt.date": function(_val){
            this.getEquipList(true)
        }
    },
    created(){
        this.getEquipList()
    },
    methods: {
        onLoad(){
            this.page.pageNo++
            this.getEquipList()
        },
        filterData(){
            this.getEquipList(true)
        },
        getEquipList(_reset){
            if(_reset){
                this.page.pageNo = 1
                this.page.total = 0
                this.list = []
            }
            getEquipList({
                companyId: this.equipInfo.companyId,
                equipType: this.equipInfo.equipType,
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                type: 2, // 类型1月 2日 3自定义
                date: formatDate(this.calendarOpt.date),
                debugState: this.debugState
            }).then(res => {
                this.list = this.list.concat(res.content.list || [])
                this.page.total = res.content.totalRecords
                this.finished = this.list.length >= this.page.total
            }).finally(()=>{                
                this.loading = false
            })
        },
        toDeviceInfo(info){
            this.$router.push({path: `/home/deviceDetail/${info.equipCode}`})
        },
        onCalendarConfirm(_date){            
            this.calendarOpt.showCalendar = false
            this.calendarOpt.date = _date
        }
    }    
}
</script>

<style lang="scss" scoped>
    .my-flex-row{
        display: flex;
        margin: 10px 20px 0;
        font-size: 12px;
        align-items: center;
        .filter-botton{
            font-size: 12px;
        }
        >.right-item{
            flex-grow: 1;
            text-align: right;
            .right-item-text{
                vertical-align: top;
                margin-right: 5px;
                line-height: 18px;
            }
        }
    }
    .filter-botton{        
        padding: .5rem .8rem;
    }
</style>